import contact from './lib/contact'
import rebox from './lib/rebox'
import { toggleBelowFold, scrollSpy, smoothScroll } from './lib/scroll'

$(document).ready(function () {
  contact('#contact-form')
  rebox('.slick-slide')
  toggleBelowFold()
  // scrollSpy('.header-nav-sub:visible', {offset: 0})
  smoothScroll('a[href^="#"]', {offset: 200})
  $('.header-nav-list > li:first-child .second-nav--wrapper').remove()

  $(document).scroll(function () {
    var scroll = $(this).scrollTop()
    if ($(window).width() >= 991 && scroll > 0) {
      $('.header').addClass('fixed')
    } else {
      $('.header').removeClass('fixed')
    };
  })
  
    if ($(window).width() <= 1199) {
    function toggleOpen(item) {
      // Toggle 'open' class for the clicked item
      item.classList.toggle('open');
  
      // Close other open items within the same level
      var siblings = item.parentElement.children;
      Array.from(siblings).forEach(function (otherItem) {
        if (otherItem !== item && otherItem.classList.contains('open')) {
          otherItem.classList.remove('open');
        }
      });
    }
  
    document.querySelectorAll('.header-nav-list > li, .second-level-sub-navigation > li, .third-level-mobile > li').forEach(function (item) {
      // Add 'click' event
      item.addEventListener('click', function (event) {
        event.stopPropagation();
        toggleOpen(item);
      });
    });
  }
})

$('.carousel.gallery-slider .neos-contentcollection').slick({
  dots: true,
  arrows: true,
  infinite: true,
  speed: 600,
  cssEase: 'linear',
  ease: 'ease-in',
  slidesToShow: 1,
  autoplay: false,
  centerMode: false,
  useTransform: true,
  variableWidth: true,
  pauseOnHover: true,
  touchMove: true,
  responsive: [
    {
      breakpoint: 990,
      settings: {
        centerMode: true,
        centerPadding: '55px',
        speed: 300
      }
    },
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        variableWidth: false,
        centerPadding: '10px',
        speed: 200
      }
    }
  ]
})

$(window).scroll(function() {
  if ($(this).scrollTop() >= 600) {
      $('.back-to-top').fadeIn(300).addClass('visible');
  } else {
      $('.back-to-top').fadeOut(300).removeClass('visible');
  };

  if ($(this).scrollTop() <= 2000) {
    $('.widget--wrapper').fadeIn(300).addClass('visible');
  } else {
      $('.widget--wrapper').fadeOut(300).removeClass('visible');
  }
});


$('.back-to-top').click(function() {
  $('body,html').animate({
      scrollTop : 0
  }, 300);
});

const counters = document.querySelectorAll('.amount-item');
const speed = 300;

counters.forEach( counter => {
   const animate = () => {
      const value = +counter.getAttribute('amount');
      const data = +counter.innerText;
     
      const time = value / speed;
     if(data < value) {
          counter.innerText = Math.ceil(data + time);
          setTimeout(animate, 300);
        }else{
          counter.innerText = value;
        }
     
   }
   
   animate();
});

$(document).ready(function () {
  // Add class 'active' to section when near top of viewport
  const iconBgOut = document.querySelectorAll('.iconBgOut')
  const iconBgOutLeft = document.querySelectorAll('.iconBgOutLeft')
  const iconBg = document.querySelectorAll('.iconBg')

  window.onscroll = function () {
    iconBgOut.forEach(section => {
      if (section.getBoundingClientRect().top <= 900) {
        section.classList.add('active')
      }
    });
    
    iconBgOutLeft.forEach(section => {
      if (section.getBoundingClientRect().top <= 900) {
        section.classList.add('active')
      }
    })
    
    iconBg.forEach(section => {
      if (section.getBoundingClientRect().top <= 200) {
        section.classList.add('active')
      }
    })
  }
})

